import { localUserRole, localUserToken } from '@libs/utils/utilLocalStorage'

/** 셀러와 어드민의 URL 매핑을 구분하기 위한 타입 */
type UserType = 'seller' | 'admin'

export const getRedirectManageProductUrlV3 = (pathname: string, userType: UserType = 'admin') => {
  const host =
    process.env.FITPETMALL_ENV === 'production' ? 'https://admin.fitpetmall.com' : 'https://admin-stage.fitpetmall.com'

  const basePath = userType === 'seller' ? '/seller/product' : '/product'

  switch (pathname) {
    case '/products/request-product-list':
      return `${host}${basePath}/request-list`
    case '/products/product-list':
      return `${host}${basePath}/product-list`
    case '/products/delete-product-list':
      return `${host}${basePath}/wait-delete-list`
    case '/products/products/add':
      return `${host}${basePath}/register`
    case '/products/excel-upload':
      return `${host}${basePath}/unregister-list`
    case '/products/category':
      return `${host}${basePath}/category`
    case '/products/products-add':
      return `${host}${basePath}/register`
    default:
      return ''
  }
}

export const redirectManageProductUrlV3 = (pathname: string) => {
  const token = localUserToken.load()
  const redirectUrl = getRedirectManageProductUrlV3(pathname, 'admin')
  if (redirectUrl) {
    window.open(`${redirectUrl}?token=${token}`, '_blank')
  }
}

export const redirectManageProductUrlV3ForSeller = (pathname: string) => {
  const token = localUserToken.load()
  const redirectUrl = getRedirectManageProductUrlV3(pathname, 'seller')
  if (redirectUrl) {
    window.open(`${redirectUrl}?token=${token}`, '_blank')
  }
}
